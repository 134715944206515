import React from 'react'
import SbEditable from 'storyblok-react'
import { Box, useMediaQuery } from '@material-ui/core'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import ACC_THEME from '@themes'

const TopNavLinkItemWithSubMenuLinks = (props) => {
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))

  return (
    <SbEditable key={props.blok._uid} content={props.blok}>
      {!handHeldDevice ? (
        <Box display="flex" flexDirection="column" alignItems="space-between">
          <Box display="flex" mb={1}>
            {renderBloks(props.blok.itemLink)}
          </Box>
          <Box>
            {props.blok.submenuLinks.map((link) => (
              <Box my={0.5} key={link._uid}>
                {renderBlok(link)}
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box mt={3}>
          {props.blok.submenuLinks.map((link) => (
            <Box my={0.5} key={link._uid}>
              {renderBlok(link)}
            </Box>
          ))}
        </Box>
      )}
    </SbEditable>
  )
}

export default TopNavLinkItemWithSubMenuLinks
